import clsx from 'clsx'
import { Icon, Preview } from '@/ui'
import { showUntitledText } from '@/utils/parseString'
import { CardProps } from '../cardsTypes'
import { CardDocType } from '../components/CardDocType'
import { useFieldContext } from '@/contexts'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'
import { useLiveQuery } from 'dexie-react-hooks'
import { queryDocChildrenByType } from '@/db/docs/queries.ts'
import { groupArr } from '@/utils/docsSort.ts'
import get from 'lodash.get'
import { useMemo } from 'react'
import { objToLabelArr } from '@/schema/hooks/useGroup.ts'

import css from '../card.module.css'
import { useSort } from '@/schema/hooks'
import { useSchemaState } from '@/contexts/schema'
import { StatusFieldType } from '@common/interfaces/fields/status-field.interface'

export const CardWithGrid = ({ doc, docSchema, children, headerElements, setImage }: CardProps) => {
  const { field } = useFieldContext<ChildrenFieldType>()
  const styles = field?.layout?.card?.styles
  const secondaryStyles = field?.layout?.card?.secondaryStyles
  const fontSize = field.layout?.card?.fontSize
  const fontColor = field.layout?.card?.fontColor
  const lineHeight = field.layout?.card?.lineHeight
  const backgroundColor = doc.fields.color ?? ''
  const gridStyles = field.layout?.card?.gridStyles

  const { type = '', fieldName = '' } = field.layout?.card?.query ?? {}

  const docs = useLiveQuery(() => queryDocChildrenByType(doc._id, type), [doc._id, type]) ?? []
  const docsSchema = useSchemaState((state) => state.docTypes)

  const phase = docsSchema['Phase']
  const stageField: StatusFieldType | any = phase?.fields.find((item) => item.name === 'stage')
  let options = []
  if (stageField) {
    options = stageField.options
  }

  const sortedDocs = useSort(docs, [{ name: 'sortOrder' }])

  const groupedDocs = useMemo(() => {
    const entries = groupArr(sortedDocs, (docItem) => {
      let groupKey = docItem.fields[fieldName] as string
      if (fieldName.includes('.')) {
        groupKey = get(docItem.fields, fieldName) as string
      }
      if (groupKey) return groupKey
    })
    options.forEach((option: any) => {
      if (!entries[option.value]) {
        entries[option.value] = []
      }
    })
    return objToLabelArr(entries)
  }, [docs, fieldName])

  return (
    <div className="relative overflow-hidden">
      <div
        className="grid grid-cols-2 items-center"
        style={{ backgroundColor: backgroundColor ? (backgroundColor as string) : '#808080' }}
      >
        <div className="absolute top-2 left-0 w-full z-20">{headerElements}</div>

        <div className="flex flex-col gap-4 p-9">
          <div>
            <CardDocType hide={docSchema?.hideType} type={docSchema?.niceName} className="pb-1.5" />
            <h4
              title={doc.title}
              className={clsx('text-heading text-neutral900 break-words')}
              style={{ fontSize, color: fontColor, lineHeight }}
            >
              {showUntitledText(doc.title)}
            </h4>
          </div>
        </div>

        <div style={styles}>
          {doc.fields.image ? (
            <Preview
              fit="cover"
              className="w-full h-full"
              styles={secondaryStyles}
              image={doc.fields.image}
              iconSize={36}
              onClick={(e) => setImage?.(e, doc.fields.image)}
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center bg-neutral200">
              <Icon name="image" size={36} />
            </div>
          )}
        </div>
      </div>

      <div className="p-4 border border-[#808080] border-t-0">
        <div
          style={gridStyles}
          className="grid grid-flow-col grid-cols-[repeat(auto-fit,minmax(120px,1fr))] gap-0"
        >
          {groupedDocs
            ?.sort((a, b) => b.label.length - a.label.length)
            .map((entry) => (
              <div 
                key={entry.label} 
                className="relative overflow-visible"
                style={{ paddingRight: '16px' }}
              >
                <div
                  className={clsx(
                    'bg-neutral400 relative text-[14px] flex items-center justify-center h-12 mb-4 pl-4 pr-1',
                    entry.docs.length ? css.arrow : css.arrowGrey,
                    { 
                      '!bg-[#FFCF02]': entry.docs.length,
                      'cursor-pointer': entry.docs.length,
                      [css.cardHover]: entry.docs.length
                    },
                  )}
                  style={{
                    ...(entry.docs?.[0] && {
                      backgroundColor: entry.docs[0]?.fields?.secondaryColor as string,
                    }),
                  }}
                >
                  <span className="mx-auto text-center text-[14px]">
                    <b>{entry.label}</b>
                  </span>
                </div>

                {/* <div className="flex flex-wrap gap-3">
                  {entry.docs.map((item) => (
                    <div
                      className="bg-neutral400 text-center flex text-[12px] items-center justify-center w-[100px] h-[95px] p-1 border-t-[10px] border-t-[#FFCF02]"
                      style={{
                        borderTopColor: item?.fields?.secondaryColor as string,
                        backgroundColor: item?.fields?.color as string,
                      }}
                      key={item.title}
                    >
                      {item.title}
                    </div>
                  ))}
                </div> */}
              </div>
            ))}
        </div>

        <div className="flex items-center justify-between pt-4">
          <div>{children}</div>
          <div className="flex items-center gap-3">
            <p>Explore this {docSchema?.niceName}</p>

            <div className="bg-[#FFCF02] rotate-45 flex items-center justify-center w-10 h-10 rounded-full">
              <Icon name="arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
