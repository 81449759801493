import {
  useState,
  createElement,
  FunctionComponent,
  MouseEvent,
  useCallback,
  useEffect,
} from 'react'
import Masonry from 'react-layout-masonry'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import clsx from 'clsx'
import { ChildrenFieldType } from '@common/interfaces/fields/link-field.interface.ts'
import { useDocContext, useFieldContext } from '@/contexts'
import { Doc, FieldValueType } from '@/db'
import { useGroup, useSort } from '@/schema/hooks'
import { CardProps } from '@/schema/cards/cardsTypes.ts'
import { CardFooterElements, CardHeaderElements } from '@/schema/cards/components/CardElements'
import { ListEmptyState } from '@/schema/cards/ListEmptyState.tsx'
import { AttachmentCard } from '@/schema/cards/variants/AttachmentCard.tsx'
import { CarouselCard } from '@/schema/cards/variants/CarouselCard.tsx'
import { ColorfulCard } from '@/schema/cards/variants/ColorfulCard.tsx'
import { DefaultCard } from '@/schema/cards/variants/DefaultCard.tsx'
import { FullDescriptionCard } from '@/schema/cards/variants/FullDescriptionCard.tsx'
import { GoalCard } from '@/schema/cards/variants/GoalCard.tsx'
import { HorizontalCard } from '@/schema/cards/variants/HorizontalCard.tsx'
import { ImpactCard } from '@/schema/cards/variants/ImpactCard.tsx'
import { ListItem2RowsCard } from '@/schema/cards/variants/ListItem2RowsCard.tsx'
import { ListItemCard } from '@/schema/cards/variants/ListItemCard.tsx'
import { NoImageCard } from '@/schema/cards/variants/NoImageCard.tsx'
import { OneFourthCard } from '@/schema/cards/variants/OneFourthCard.tsx'
import { PersonaCard } from '@/schema/cards/variants/PersonaCard.tsx'
import { PrimaryCard } from '@/schema/cards/variants/PrimaryCard.tsx'
import { SelectCard } from '@/schema/cards/variants/SelectCard.tsx'
import { StatusCard } from '@/schema/cards/variants/StatusCard.tsx'
import { TeamMemberCard } from '@/schema/cards/variants/TeamMemberCard.tsx'
import { TinyPersonaCard } from '@/schema/cards/variants/TinyPersonaCard.tsx'
import { getClassName, getGapClassNames } from '@/utils/docsList.ts'
import { scm, useSchemaState } from '@/contexts/schema'
import './carousel.css'
import { FAQCard } from './variants/FAQCard'
import { FAQVerticalCard } from './variants/FAQVertical'
import { FlipCard } from './variants/FlipCard'
import { HorizontalPersonaCard } from './variants/HorizontalPersonaCard'
import { HorizontalSmallCard } from './variants/HorizontalSmall'
import { IkeaCard } from './variants/IkeaCard'
import { SimpleCard } from './variants/SimpleCard'
import { WithIconCard } from './variants/WithIconCard'
import { CategoryCard } from './variants/CategoryCard'
import { KeyFactCard } from './variants/KeyFactCard'
import { CardWithImage } from '@/schema/cards/variants/CardWithImage.tsx'
import { Button, Icon } from '@/ui'
import { VerticalCarouselCard } from '@/schema/cards/variants/VerticalCarouselCard.tsx'
import { CardWithImageVertical } from '@/schema/cards/variants/CardWithImageVertical.tsx'
import { MiniCard } from '@/schema/cards/variants/MiniCard.tsx'
import { ButtonCard } from '@/schema/cards/variants/ButtonCard.tsx'
import { useFilterDocs } from '@/features/filter'
import { ImpactStoryCard } from '@/schema/cards/variants/ImpactStoryCard.tsx'
import { IkeaDefaultCard } from '@/schema/cards/variants/IkeaDefaultCard.tsx'
import { GroupLabelCard } from '@/schema/cards/GroupLabelCard.tsx'
import { AreaRenderer } from '@/schema'
import { CardWrapper } from './CardWrapper'
import { FullImageCard } from './variants/FullImageCard'
import { setNewDoc } from '@/store/docs'
import { useAppDispatch } from '@/store'
import { useNavigate } from 'react-router-dom'
import { InfoCard } from './variants/InfoCard'
import { InfoCard2 } from './variants/InfoCard_2'
import { TemplateCard } from '@/schema/cards/variants/TemplateCard.tsx'
import { BannerCard } from '@/schema/cards/variants/BannerCard.tsx'
import { CardWithGrid } from '@/schema/cards/variants/CardWithGrid.tsx'
import { usePath } from '@/hooks'
import { SmallCard } from './variants/SmallCard'
import { TileCard } from './variants/TileCard.tsx'
import { groupArr } from '@/utils/docsSort.ts'
import get from 'lodash.get'
import { objToLabelArr } from '@/schema/hooks/useGroup.ts'
import css from '@/schema/cards/card.module.css'
import { ReferenceCard } from '@/schema/cards/variants/ReferenceCard.tsx'
import { StatusFieldType } from '@common/interfaces/fields/status-field.interface.ts'
// import { StatusFieldType } from '@common/interfaces/fields/status-field.interface.ts'

const GRID_CARD_TYPE_COMPONENTS: Record<string, FunctionComponent<CardProps>> = {
  default: DefaultCard,
  horizontal: HorizontalCard,
  horizontalPersona: HorizontalPersonaCard,
  horizontalSmall: HorizontalSmallCard,
  persona: PersonaCard,
  colorful: ColorfulCard,
  oneFourth: OneFourthCard,
  listItem: ListItemCard,
  listItem2Rows: ListItem2RowsCard,
  noImage: NoImageCard,
  select: SelectCard,
  fullDescription: FullDescriptionCard,
  teamMember: TeamMemberCard,
  tinyPersona: TinyPersonaCard,
  flipCard: FlipCard,
  simpleCard: SimpleCard,
  goalCard: GoalCard,
  carousel: CarouselCard,
  status: StatusCard,
  impact: ImpactCard,
  attachment: AttachmentCard,
  primary: PrimaryCard,
  faq: FAQCard,
  ikea: IkeaCard,
  faqVertical: FAQVerticalCard,
  withIcon: WithIconCard,
  categoryCard: CategoryCard,
  keyFact: KeyFactCard,
  withImage: CardWithImage,
  verticalCarousel: VerticalCarouselCard,
  withImageVertical: CardWithImageVertical,
  miniCard: MiniCard,
  buttonCard: ButtonCard,
  impactStoryCard: ImpactStoryCard,
  ikeaDefault: IkeaDefaultCard,
  '001.FullImageCard': FullImageCard,
  '002.InfoCard': InfoCard,
  '003.InfoCard': InfoCard2,
  fullImage: FullImageCard,
  '004.TemplateCard': TemplateCard,
  '005.BannerCard': BannerCard,
  gridCard: CardWithGrid,
  '006.A.SmallCard': SmallCard,
  tileCard: TileCard,
  referenceCard: ReferenceCard,
}

const GRID_CARD_TYPE_HEIGHTS: Record<string, number> = {
  default: 168,
  horizontal: 162,
  persona: 162,
  colorful: 200,
  oneFourth: 110,
  listItem: 48,
  listItem2Rows: 80,
  noImage: 70,
  select: 26,
  fullDescription: 85,
  teamMember: 75,
  tinyPersona: 32,
}

export const getCardComponent = (type = 'default') => {
  if (GRID_CARD_TYPE_COMPONENTS[type]) {
    return GRID_CARD_TYPE_COMPONENTS[type]
  }

  return DefaultCard
}

export const List = ({
  docs,
  setCurrentDoc,
  type,
  parentDoc,
}: {
  docs: Doc[]
  setCurrentDoc: (doc: Doc) => void
  type?: 'children' | 'multilink' | 'query'
  parentDoc?: Doc
}) => {
  const { doc } = useDocContext()
  const { field, isEditable } = useFieldContext<ChildrenFieldType, Doc[]>()
  const docsSchema = useSchemaState((state) => state.docTypes)

  const { columns, gapX, gapY, card, classes, columnFlow, arrayLength } = field.layout || {
    columns: 0,
    gapX: 7,
    gapY: 7,
    card: { type: 'default' },
  }

  const enableFullImageModal = field.layout?.card?.enableFullImageModal

  // const childrenNiceNames = useMemo(
  //   () => field.allowed?.map((type) => camelToCapitalizeWords(type)).join(', ') ?? 'documents',
  //   [field],
  // )

  const { setModal } = usePath()
  const filteredDocs = useFilterDocs(docs)
  const sortedDocs = useSort(filteredDocs, field.sort)
  const { groupsArr } = useGroup(sortedDocs, field.group)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const openModal = (item: Doc, slug: string) => {
    if (isEditable) {
      navigate({
        pathname: location.pathname,
        search: `?row=${slug}`,
      })
      dispatch(setNewDoc(item))
    }
  }

  const onCardDelete = useCallback((event: MouseEvent<HTMLButtonElement>, docEntry: Doc) => {
    event.preventDefault()
    event.stopPropagation()

    setCurrentDoc(docEntry)
  }, [])

  const createCardElement = useCallback(
    (item: Doc, index?: number) => {
      const cardElement = createElement(
        getCardComponent(card?.type || 'default'),
        {
          doc: item,
          docSchema: docsSchema[item._type],
          isEditMode: type !== 'query' && isEditable,
          onDelete: (e) => onCardDelete(e, item),
          author: card?.elements?.author,
          date: card?.elements?.date,
          showQuote: card?.elements?.showQuote,
          parentDoc,
          index,
          headerElements: (
            <CardHeaderElements
              docItem={item}
              onDelete={(e) => onCardDelete(e, item)}
              isEditMode={isEditable}
              date={card?.elements?.date}
              docSchema={docsSchema[item._type]}
            />
          ),
          setImage: enableFullImageModal
            ? (e: MouseEvent, image: FieldValueType) => {
                e.preventDefault()
                setModal({
                  type: 'image',
                  image,
                })
              }
            : undefined,
        },
        <CardFooterElements
          docSchema={docsSchema[item._type]}
          docItem={item}
          isEditMode={isEditable}
          date={card?.elements?.date}
          styles={card?.footerElementsStyles}
        />,
      )

      const lastElementStyles =
        field.lastElementStyles && sortedDocs.length === index ? field.lastElementStyles : undefined

      return (
        <CardWrapper
          key={item._id}
          makeInactive={card?.makeInactive}
          useAsALink={card?.useAsALink}
          doc={item}
          isEditable={isEditable}
          lastElementStyles={lastElementStyles}
        >
          {cardElement}
        </CardWrapper>
      )
    },
    [card, type, isEditable, onCardDelete, docsSchema],
  )

  const [activeTabDoc, setActiveTabDoc] = useState<Doc>(sortedDocs?.[0])
  const [selectedStage, setSelectedStage] = useState<string | null>(null);

  // Need to update doc in tab if values were changed
  useEffect(() => {
    if (activeTabDoc && field.viewComponent === 'tabs') {
      const currentDoc = sortedDocs.find((item) => item._id === activeTabDoc._id)
      if (currentDoc) setActiveTabDoc(currentDoc)
    }
  }, [sortedDocs])

  const createDocument = useCallback(async () => {
    const dSchema = scm.getDocSchema(field.allowed?.[0])

    if (dSchema) {
      const payload: Partial<Doc> = {
        _type: field.allowed?.[0],
        _parentId: doc._id,
        _ancestors: [...doc._ancestors, doc._id],
      }

      if (dSchema?.defaultFieldValues) {
        payload.fields = dSchema.defaultFieldValues
      }

      navigate({
        pathname: location.pathname,
        search: `?row=newDocument`,
      })
      dispatch(setNewDoc({ ...payload, parentDoc: doc, isNew: true }))
    }
  }, [doc, field])

  // Defined at the top level so it's available to handleTabClick
  let stageField: StatusFieldType | any = null;
  
  const handleTabClick = (item: Doc) => {
    setActiveTabDoc(item);
    if (stageField && item.fields.stage) {
      setSelectedStage(item.fields.stage as string);
    }
  }

  if (!sortedDocs.length) {
    return <ListEmptyState height={GRID_CARD_TYPE_HEIGHTS[card?.type || 'default'] || 168} />
  }

  if (groupsArr) {
    return (
      <div className={clsx(columnFlow ? 'flex overflow-x-auto' : 'grid gap-7')}>
        {groupsArr
          .filter((group) => group.docs?.length)
          .map((group) => (
            <div
              key={group.label}
              className={clsx('rounded-md mr-4 py-4', columnFlow && 'bg-neutral100')}
            >
              {group.label &&
                (field?.group?.byLinkedDocument ? (
                  <GroupLabelCard id={group.label} />
                ) : (
                  <p className="text-regular font-bold mb-4 mx-2">
                    {group.label}{' '}
                    {field?.group?.showCount && (
                      <span className={clsx('ml-2', columnFlow && 'text-neutral700 ')}>
                        {group.docs.length}
                      </span>
                    )}
                  </p>
                ))}

              <div
                className={clsx(
                  getClassName(columns),
                  getGapClassNames(gapX, gapY),
                  'mx-2',
                  classes,
                )}
              >
                {group.docs.map((entity) => createCardElement(entity))}
              </div>
            </div>
          ))}
      </div>
    )
  }

  if (field.isCarousel || field.viewComponent === 'carousel') {
    const chunkSize = columns ?? 5

    const result = docs.reduce((resultArray: Array<Array<Doc>>, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

    result.length = arrayLength || result.length
    const { axis, showIndicators, showArrows } = field?.verticalCarousel ?? {
      axis: 'horizontal',
      showIndicators: true,
      showArrows: false,
    }

    return (
      <div className="list-carousel overflow-hidden">
        <ReactCarousel
          swipeable
          showArrows={showArrows}
          showStatus={false}
          showThumbs={false}
          autoPlay
          infiniteLoop
          axis={axis}
          showIndicators={showIndicators}
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext &&
            axis === 'vertical' && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="w-7 h-7 rounded-full flex items-center justify-center absolute right-4 btn-next"
              >
                <Icon name="chevron-down" color="#000" />
              </button>
            )
          }
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev &&
            axis === 'vertical' && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="w-7 h-7 rounded-full flex items-center justify-center absolute right-4 btn-prev"
              >
                <Icon name="chevron-up" color="#000" />
              </button>
            )
          }
        >
          {result.map((chunk) => (
            <div
              key={chunk?.[0]?._id}
              className={clsx(
                'grid',
                getClassName(columns),
                getGapClassNames(gapX, gapY),
                classes,
                {
                  'grid-cols-1': columns === 1,
                },
              )}
            >
              {chunk.map((item) => createCardElement(item))}
            </div>
          ))}
        </ReactCarousel>
      </div>
    )
  }

  if (field.viewComponent === 'masonry') {
    return (
      <>
        <div>
          <Masonry columns={columns || 3} gap={20}>
            {sortedDocs.map((item) => createCardElement(item))}
          </Masonry>
        </div>
      </>
    )
  }

  if (field.viewComponent === 'tabs') {
    const dSchema = scm.getDocSchema(activeTabDoc?._type)
    const { fieldName = '' } = field.layout?.card?.query ?? {}

    stageField = dSchema?.fields.find((item) => item.name === 'stage')
    let options = []
    if (stageField) {
      options = stageField.options
    }

    const entries = groupArr(sortedDocs, (docItem) => {
      let groupKey = docItem.fields[fieldName] as string
      if (fieldName.includes('.')) {
        groupKey = get(docItem.fields, fieldName) as string
      }
      if (typeof groupKey === 'string' && groupKey) {
        return groupKey
      }
      return 'others'
    })

    options.forEach((option: any) => {
      if (!entries[option.value]) {
        entries[option.value] = []
      }
    })

    const groupedDocs = objToLabelArr(entries) ?? []

    // NEW: Set the first non-empty tab as active by default
    useEffect(() => {
      // Only set default if no stage is currently selected
      if (stageField && !selectedStage && groupedDocs.length > 0) {
        // Find the first group that has documents
        const firstGroupWithDocs = groupedDocs.find(group => group.docs.length > 0);
        if (firstGroupWithDocs) {
          setSelectedStage(firstGroupWithDocs.label);
        }
      }
    }, [stageField, groupedDocs, selectedStage]);

    // Get all documents across all groups into a single flat array
    // Filter by selected stage if one is active
    const allDocs = selectedStage 
      ? sortedDocs.filter(doc => doc.fields.stage === selectedStage)
      : sortedDocs;

    // NEW: Set the first visible card as active by default
    useEffect(() => {
      if (allDocs.length > 0 && !activeTabDoc) {
        const firstVisibleDoc = allDocs[0];
        setActiveTabDoc(firstVisibleDoc);
        if (stageField && firstVisibleDoc.fields.stage) {
          setSelectedStage(firstVisibleDoc.fields.stage as string);
        }
      }
    }, [allDocs, stageField]);

    // Handle tab group click
    const handleTabGroupClick = (stageValue: string, hasDocuments: boolean) => {
      // Only process clicks for tabs that have documents and when stageField exists
      if (stageField && hasDocuments) {
        if (selectedStage === stageValue) {
          // If already selected, clear the filter
          setSelectedStage(null);
          // Set the first document from all docs as active
          if (sortedDocs.length > 0) {
            setActiveTabDoc(sortedDocs[0]);
          }
        } else {
          // Set the selected stage to filter the docs
          setSelectedStage(stageValue);
          // Find and set the first document from the newly selected stage
          const firstDocInStage = sortedDocs.find(doc => doc.fields.stage === stageValue);
          if (firstDocInStage) {
            setActiveTabDoc(firstDocInStage);
          }
        }
      }
    };

    return (
      <div>
        {isEditable && (
          <Button className="mb-2" onClick={createDocument}>
            Add new
          </Button>
        )}

      

        <div className="relative">
          {/* Main tabs container with equal spacing */}
          <div
            className="flex gap-0 relative w-full overflow-visible"
          >
            {groupedDocs
              ?.sort((a, b) => b.label.length - a.label.length)
              .map((entry) => {
                // Determine if this is the active tab
                const isActive = stageField && selectedStage === entry.label && entry.docs.length > 0;
                // Determine if this tab has documents
                const hasDocuments = entry.docs.length > 0;
                
                return (
                  <div 
                    key={entry.label} 
                    className="relative overflow-visible"
                    style={{
                      flex: isActive ? '3 0 300px' : '1 0 100px',
                      transition: 'flex 0.2s ease-out',
                      paddingRight: '16px'
                    }}
                  >
                    {/* Tab header with proper arrow styling */}
                    <div
                      className={clsx(
                        'relative text-[14px] capitalize flex items-center justify-center h-12 pl-4 pr-1 text-center w-full overflow-visible',
                        isActive ? css.arrow : css.arrowGrey,
                        hasDocuments && !isActive && css.arrowHover,
                        { 
                          'cursor-pointer': hasDocuments,
                          'opacity-50': !hasDocuments,
                          'font-bold': hasDocuments,
                          'z-10': isActive,
                        },
                      )}
                      style={{
                        backgroundColor: isActive 
                          ? '#FFCF02' 
                          : hasDocuments 
                            ? '#f2f2f2'
                            : '#e5e5e5',
                        transition: 'background-color 0.2s ease-out',
                      }}
                      onClick={() => handleTabGroupClick(entry.label, hasDocuments)}
                      aria-disabled={!hasDocuments}
                    >
                      <span className={clsx(
                        "mx-auto text-center text-[14px]",
                        !hasDocuments && "text-neutral500 font-normal"
                      )}>
                        {entry.label}
                      </span>
                    </div>
                  </div>
                )
              })}
          </div>
          
          {/* Space between tabs and document cards */}
          <div className="mt-4">
            {/* Document cards with keyline at the top */}
            <div className="relative">
              {/* Document cards section - changed to single line with horizontal scroll */}
              <div className="flex flex-nowrap overflow-x-auto gap-6 pt-[16px] mb-6 pb-2">
                {allDocs.map((item) => (
                  <div
                    key={item._id}
                    className={clsx(
                      'relative text-sm flex items-center justify-center w-[160px] h-[110px] cursor-pointer overflow-visible flex-shrink-0',
                      css.cardHover,
                      item._id === activeTabDoc?._id ? css.cardArrow : css.cardArrowGrey,
                      { [css.cardArrowActive]: item._id === activeTabDoc?._id }
                    )}
                    onClick={() => handleTabClick(item)}
                    style={{
                      borderTopColor: item?.fields?.secondaryColor as string,
                    }}
                  >
                    <span className={clsx(
                      'flex items-center justify-center h-full w-full z-10 px-2 line-clamp-3 text-center max-w-[140px] font-bold text-[14px]', 
                      { 'font-bold': item._id === activeTabDoc?._id }
                    )}>
                      {item.title}
                    </span>
                    {isEditable && (
                      <div className="absolute top-1 right-1 flex flex-col items-center gap-1 z-20 scale-75">
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            openModal(item, item._slug);
                          }}
                          icon="file-pen-line"
                        />

                        <Button 
                          onClick={(e) => {
                            e.stopPropagation();
                            onCardDelete(e, item);
                          }} 
                          icon="trash" 
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* Content area with proper separation */}
              {!isEditable && activeTabDoc && (
                <div className="grid gap-5">
                  <AreaRenderer fields={dSchema?.fields} doc={activeTabDoc} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={clsx(columns && getClassName(columns), getGapClassNames(gapX, gapY), classes)}>
      {sortedDocs.map((item, index) => createCardElement(item, index + 1))}
    </div>
  )
}
