import { Doc } from '@/db'
import { usePath } from '@/hooks'
import { useAppDispatch } from '@/store'
import { setNewDoc } from '@/store/docs'
import { KeyValueData } from '@common/interfaces/fields/keyvalue-field.interface'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'

export const CardWrapper = ({
  children,
  makeInactive,
  doc,
  isEditable,
  lastElementStyles,
  useAsALink,
}: {
  children: JSX.Element
  makeInactive?: boolean
  useAsALink?: boolean
  doc: Doc
  isEditable: boolean
  lastElementStyles?: { [key: string]: string }
}) => {
  const { getDocPath } = usePath()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const field = doc.fields?.url as KeyValueData
  const link = field?.url || field
  const openModal = (item: Doc, slug: string) => {
    if (isEditable) {
      navigate({
        pathname: location.pathname,
        search: `?row=${slug}`,
      })
      dispatch(setNewDoc(item))
    }
  }
  if (makeInactive) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        key={doc._id}
        onClick={() => openModal(doc, doc._slug)}
        className={clsx(isEditable && 'cursor-pointer')}
      >
        {children}
      </div>
    )
  }

  if (useAsALink && link && typeof link === 'string') {
    return (
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}
      >
        {children}
      </a>
    )
  }

  return (
    <Link
      aria-label={'Go to ' + doc.title}
      key={doc._id}
      to={getDocPath(doc)}
      style={lastElementStyles}
    >
      <div className="h-full transition-transform hover:scale-[1.01]">{children}</div>
    </Link>
  )
}
